import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import VerifyKyc from "./VerifyKyc";
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl;

const UsersCompleteRecord = ({ auth, history }) => {
    const [nationality, setNationality] = useState("indian");
    const [records, setRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    
    const [popUpModal, setPopModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    

    const columnsIndian = [
        {
            key: "_id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1
        },
        {
            key: "id",
            text: "Id",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "firstName",
            text: "Name",
            className: "firstName",
            align: "left",
            sortable: true,
        },
        {
            key: "mobile_no",
            text: "Mobile Number",
            className: "email",
            align: "left",
            sortable: true
        },
        {
            key: "email",
            text: "Email",
            className: "email",
            align: "left",
            sortable: true
        },
        // {
        //     key: "country_code",
        //     text: "Nationality",
        //     className: "country_code",
        //     align: "left",
        //     sortable: true,
        //     cell: (record) => {
        //         return (
        //             <>
        //                 {record.country_code === "+91" ? "Indian" : "Non-Indian"}
        //             </>
        //         );
        //     }
        // },
        {
            key: "createdAt",
            text: "Date",
            className: "date",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>{Moment(new Date(record.kyc_verify_date*1000)).format("MMM DD YYYY hh:mm A")}</>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <button onClick={() => openPopUpModel(record)} className="admin_btn">View</button>
                    // <Link to={"/verifyKyc/" + record.id + "/" + 2} className="admin_btn">view</Link>
                );
            }
        }
    ];

    const config = {
        page_size: 20,
        length_menu: [20, 50, 100],
        filename: "Verify Completed List ",
        no_data_text: 'No user found!',
        button: {
            print: false,
            csv: true
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    useEffect(() => {
        if (auth.user.verifyComplete !== undefined && auth.user.verifyComplete !== 1) {
            history.push("/dashboard");
        }
        getData();
    }, [auth, history]);

    const openPopUpModel = (data) => {
        console.log(data)
        setSelectedData(data)
        // sessionStorage.setItem("popshow", false);
        setPopModalOpen(true);
      };

    const getData = () => {
        axios
            .post(baseUrl + "/api/user-complete-data", { lang: 1 })
            .then(res => {
                setRecords(res.data);
                const filteredData = res.data.filter((filterdData) => {
                    return filterdData.country === "113";
                });
                setFilteredRecords(filteredData);
            })
            .catch(() => { });
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value === "indian") {
            const filterData = records.filter((filterdData) => filterdData.country_code === "+91");
            setFilteredRecords(filterData);
        }
        if (value === "non-indian") {
            const filterData = records.filter((filterdData) => filterdData.country_code !== "+91");
            setFilteredRecords(filterData);
        }
        setNationality(value);
    };

    const deleteRecord = (record) => {
        axios
            .post(baseUrl + "/api/user-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch(() => { });
        getData();
    };

    const pageChange = (pageData) => {
        console.log("OnPageChange", pageData);
    };

    return (
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                        <h4 className="mt-2 man_hadding mb-3">Korean Users Complete Verify List</h4>
                        <div className="white_box">
                            <ReactDatatable
                                config={config}
                                records={filteredRecords}
                                columns={columnsIndian}
                                onPageChange={pageChange}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <Modal
                    style={{ width: "100%" }}
                    show={popUpModal}
                    size="lg"
                    fullscreen={true}
                    onHide={() => setPopModalOpen(false)}
                    keyboard={false}
                >
                     <Modal.Header >
                     <div style={{height:"28px"}}>
                     {/* <button className="admin_btn" onClick={()=>setPopModalOpen(false)} style={{position:"absolute",right:"25px"}}>X</button> */}
                     <i style={{"font-size":"24px" , position:"absolute",right:"25px",cursor:"pointer"}} class="fa fa-close" onClick={()=>setPopModalOpen(false)}></i>
                     </div>
                    </Modal.Header>
                    <Modal.Body>
                        {Object.keys(selectedData).length > 0 ? <VerifyKyc match={selectedData} /> : ""}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

UsersCompleteRecord.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(UsersCompleteRecord);
