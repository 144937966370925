// App.jsx
import React, { useState, useEffect } from 'react';
import * as xrpl from 'xrpl';

const Xrpt = () => {
  const [wallet, setWallet] = useState(null);
  const [status, setStatus] = useState('');
  const [destination, setDestination] = useState('');
  const [amount, setAmount] = useState('1'); // default: 1 XRP
  const [walletAddress, setWalletAddress] = useState(null);

  // Demo wallet connection – in production, do NOT use a prompt to capture secrets!

  useEffect(() => {
    console.log(xrpl);
  }, [])
  const connectWallet = async () => {
    // const wallet = xrpl.Wallet.fromSeed("f8fdf6b55312aaaa99d342bf80b57f43a3c86506ea3ea7c6473bb486918425805a7da078649991465a32479b12683a46307540d3d4f2814d"); 
    // console.log(wallet);
    const client = new xrpl.Client('wss://xrplcluster.com');
    await client.connect();

    let uu = new xrpl.Wallet("ED6A09172240F8454A732BE00E8DC4C1F598220F7790992483F53F8CD6DC56DEF7","EDE474E16D2C182F59A5D7DD86A1B46AFC50235807DEB05E5A381B6F205F902E0D")
  

    //  const secret = prompt('Enter your XRP wallet secret (for demo purposes only)');
    console.log(uu);
   
    setWallet(uu);

    const payment = {
      TransactionType: "Payment",
      Account: uu.classicAddress,
      Destination: "rfsMrnJegM4jcPAPs25JtyVYoZmfHHjH6U", // Replace with actual destination
      Amount: xrpl.xrpToDrops("0.1"), // Sending 10 XRP
    };

    try {
      const prepared = await client.autofill(payment);
      console.log("Prepared Transaction:", prepared);

    //   const signed = await xumm.payload.createAndSubscribe(prepared, (tx) => {
    //     console.log("Transaction signed:", tx);
    // });

      const signed = uu.sign(prepared); // 🔹 Ensure wallet can sign
      console.log("Signed Transaction:", signed);

      const response = await client.submitAndWait(signed.tx_blob);
      console.log("Transaction Response:", response);
    } catch (error) {
      console.error("Transaction Error:", error);
    }

    // if (secret) {
    //   try {
    //     const newWallet = new xrpl.Wallet(secret);
    //     console.log(newWallet);
    //     setWallet(newWallet);
    //     setStatus(`Wallet connected: ${newWallet.address}`);
    //   } catch (error) {
    //     setStatus(`Error connecting wallet: ${error.message}`);
    //   }
    // }
  };

  const sendXRP = async () => {
    if (!wallet) {
      alert('Please connect your wallet first.');
      return;
    }
    if (!destination) {
      alert('Please enter a destination address.');
      return;
    }
    try {
      // Connect to the XRP Testnet – you can change this to mainnet if needed
      const client = new xrpl.Client('wss://xrplcluster.com');
      await client.connect();
      setStatus('Connected to XRP Testnet...');

      // Prepare a Payment transaction
      const payment = {
        TransactionType: 'Payment',
        Account: wallet.classicAddress,
        Destination: destination, // destination XRP address
        Amount: xrpl.xrpToDrops(amount), // convert XRP to drops (1 XRP = 1,000,000 drops)
      };

      console.log(payment);

      // Autofill fields like Fee and Sequence number
      const prepared = await client.autofill(payment);
      console.log(prepared);
      console.log(wallet)
      const signed = wallet.sign(prepared);
      console.log(signed);
      setStatus('Submitting transaction...');

      // Submit and wait for validation
      const result = await client.submitAndWait(signed.tx_blob);
      setStatus(`Transaction result: ${result.result.meta.TransactionResult}`);

      await client.disconnect();
    } catch (error) {
      console.log(error);
      setStatus(`Error sending XRP: ${error.message}`);
    }
  };

  const connectWallets = async () => {
    try {
      if (window.osmwallet) {
        const response = await window.osmwallet.connect();
        if (response && response.address) {
          setWalletAddress(response.address);
          console.log("Connected Wallet Address:", response.address);
        } else {
          console.error("Failed to retrieve wallet address.");
        }
      } else {
        alert("OSM Wallet not detected. Please install the OSM Wallet extension.");
      }
    } catch (error) {
      console.error("Wallet connection error:", error);
    }
  };

  return (
    <div style={{ padding: '2rem', fontFamily: 'sans-serif' }}>
      <h1>XRP Wallet Connect & Transfer (XRP Toolkit–Style)</h1>

      {!wallet ? (
        <button onClick={connectWallet}>Connect Wallet</button>
      ) : (
        <div>
          <p>
            <strong>Wallet Connectedd:</strong> {wallet.classicAddress}
          </p>
          <div style={{ marginTop: '1rem' }}>
            <input
              type="text"
              placeholder="Destination Address"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              style={{ marginRight: '1rem' }}
            />
            <input
              type="number"
              placeholder="Amount in XRP"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{ marginRight: '1rem', width: '120px' }}
            />
            <button onClick={sendXRP}>Send XRP</button>
          </div>
        </div>
      )}

      {status && (
        <div style={{ marginTop: '1rem', background: '#f0f0f0', padding: '1rem' }}>
          <strong>Status:</strong> {status}
        </div>
      )}

      <button onClick={connectWallet}>
        {walletAddress ? `Connected: ${walletAddress}` : "Connect OSM Wallet (XRP)"}
      </button>
    </div>
  );
};

export default Xrpt;