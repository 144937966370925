import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Keypair,
  Connection,
  PublicKey,
  clusterApiUrl,
  SystemProgram,
  LAMPORTS_PER_SOL,
  Transaction,
} from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  createAssociatedTokenAccountInstruction,
  TOKEN_PROGRAM_ID,
  createTransferInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { Token } from "@solana/spl-token";
import "@solana/wallet-adapter-react-ui/styles.css";

import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { isInstalled, sendPayment, getAddress } from "@gemwallet/api";
import { Contract, BrowserProvider, parseEther, parseUnits } from "ethers";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CoinWithdrawalReject from "../partials/CoinWithdrawalReject";
import CoinWithdrawalApprove from "../partials/CoinWithdrawalApprove";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { baseUrl } from "../../actions/baseUrl";

import { bscRpcUrl, binanceChainId, explorerUrlBsc, ethChainId, explorerUrlEth, EthRpcUrl, polygonChainId, test_polygonChainId, explorerUrlPolygon, polygonRpcUrl, test_polygonRpcUrl, avalancheChainId, explorerUrlAvalanche, avalancheRpcUrl, projectId, bsc_ta_usdt, test_binanceChainId, test_ethChainId, test_explorerUrlEth, test_EthRpcUrl, test_bscRpcUrl, test_explorerUrlBsc } from "../../utils/enum";
import test_abi from "../../utils/test_abi";
import eth_test_abi from "../../utils/eth_test_abi";
import eth_abi from "../../utils/eth_abi";
// import shib_abi from "../../utils/shib_abi";
import abi from "../../utils/abi";
import { etc_abi, dot_abi, link_abi, shib_abi, dodge_abi, mana_abi, dbi_abi } from "../../utils/etc_abi";


const CoinWithdrawal = () => {
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  // solana one ---> 
  const wallet = useWallet();

  console.log(address, isConnected, chainId);

  const RPC_ENDPOINT = "https://api.mainnet-beta.solana.com"; // Replace with your endpoint
  const sol_rpc_url = "https://solana-mainnet.g.alchemy.com/v2/KL0XtkBmpG0pMfcqD7B8UfFMezVg7Wfm";

  //11155111 eth 
  // 97 testnet
  // mainmet 56
  // const tronWeb = window.tronWeb;
  const [state, setState] = useState({ records: [] })
  const [currentRecord, setCurrentRecord] = useState({})
  const [disable, setDisable] = useState({})
  const [selectedTransaction, setSelectedTransaction] = useState({})
  const [coinList, setCoinList] = useState([])
  const [coinAvailable, setCoinAvailable] = useState([1, 2, 7, 9, 10, 11, 14, 17, 18, 38, 50, 51, 6, 3, 12, 46, 16, 18])

  const [addresstron, setAddresstron] = useState(null);
  const [balancetron, setBalancetron] = useState(null);
  const [walletConnectedtron, setWalletConnectedtron] = useState(false);
  const [tronWalletAddress, setTronWalletAddress] = useState(null)
  const [tronWeb, setTronWeb] = useState(null)

  //[bnb,eth,usdt,Polygon,Avalanche]
  const [inprogress, setInprogress] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)


  const [chainType, setChainType] = useState("")
  //chainType = test_
  const [rejectReason, setRejectReason] = useState("")

  const [dataFilter, setDataFilter] = useState({ coinFilter: "", typeFilter: "" })
  const [xrpwallet, setXrpwallet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);



  const mainnet = [
    {
      chainId: (chainType == "test_") ? test_binanceChainId : binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: (chainType == "test_") ? explorerUrlBsc : test_explorerUrlBsc,
      rpcUrl: (chainType == "test_") ? test_bscRpcUrl : bscRpcUrl,
    },
    {
      chainId: (chainType == "test_") ? test_ethChainId : ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: (chainType == "test_") ? test_explorerUrlEth : explorerUrlEth,
      rpcUrl: (chainType == "test_") ? test_EthRpcUrl : EthRpcUrl,
    },
    {
      chainId: (chainType == "test_") ? test_polygonChainId : polygonChainId,
      name: "Polygon",
      currency: "MATIC",
      explorerUrl: explorerUrlPolygon,
      rpcUrl: (chainType == "test_") ? test_polygonRpcUrl : polygonRpcUrl,
    },

    {
      chainId: avalancheChainId,
      name: "Avalanche",
      currency: "AVAX",
      explorerUrl: explorerUrlAvalanche,
      rpcUrl: avalancheRpcUrl,
    },
  ];

  const columns = [
    {
      key: "id",
      text: "sno",
      className: "transaction_id",
      align: "left",
      sortable: true

    },
    {
      key: "_id",
      text: "Transaction_id",
      className: "transaction_id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.tx_id}</span>;
      },
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "wallet_address",
      align: "left",
      sortable: true,
      cell: (record) => {
        const record1 = record.wallet_address;
        // console.log(record1.slice(5))
        if (record1) {
          // record1.slice(0, 20)
          return (
            <span className="wallet_a_box">
              {record1}
              <i
                className="fa fa-clone"
                onClick={() => {
                  navigator.clipboard.writeText(record.wallet_address);
                  toast.success("Copied");
                }}
              ></i>
            </span>
          );
        } else {
          return <span>{record.wallet_address}</span>;
        }
      },
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "firstName",
      text: "Name / Mobile",
      className: "email",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <div class="">{record.firstName + " " + record.lastName} {/*<br /><span class=""><i class="fa fa-envelope"></i> {record.email}</span>*/} <br /><span class=""> <i class="fa fa-mobile-phone"></i> {record.mobile}</span></div>;
      },
    },

    // {
    //   key: "mobile",
    //   text: "Mobile No.",
    //   className: "email",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "chain",
      text: "Coin Type",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Token",
      className: "email",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "amount",
    //   text: "Requested",
    //   className: "amount",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return <span>{record.coin_amount}</span>;
    //   },
    // },
    {
      key: "withdrawal_fees",
      text: "Amount",
      className: "amount",
      align: "left",
      sortable: true,
      cell: (record) => {
        var type = record.type;
        var withdrawalFee = parseFloat(record.coin_amount) - record.withdrawal_fees;
        return (
          <>
            <span>Requested:{record.coin_amount}</span><br />
            <span>Fees:
              {record.withdrawal_fees == null
                ? "Not Avaliable"
                : parseFloat(record.withdrawal_fees)}{" "}
              {/* {type == "flat" ? "Flat" : type == "percentage" ? "%" : ""} */}
            </span>
            <br />
            <span>
              Received: {record.withdrawal_fees != null ? withdrawalFee : record.coin_amount}
            </span>
          </>
        );
      },
    },
    // {
    //   key: "",
    //   text: "Received",
    //   className: "amount",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     var type = record.type;
    //     // var feeValue =
    //     // (parseFloat(record.coin_amount) *
    //     //   parseFloat(record.withdrawal_fees)) /
    //     // 100;
    //     var withdrawalFee = parseFloat(record.coin_amount) - record.withdrawal_fees;
    //     // if (type == "percentage") {
    //     //   var feeValue =
    //     //     (parseFloat(record.coin_amount) *
    //     //       parseFloat(record.withdrawal_fees)) /
    //     //     100;
    //     //   var withdrawalFee = parseFloat(record.coin_amount) - feeValue;
    //     // }
    //     // if (type == "flat") {
    //     //   withdrawalFee =
    //     //     parseFloat(record.coin_amount) -
    //     //     parseFloat(record.withdrawal_fees);
    //     // }
    //     return (
    //       <>
    //         <span>
    //           {record.withdrawal_fees != null ? withdrawalFee : record.coin_amount}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      key: "tx_hash",
      text: "Transaction Hash",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <span>{record.tx_hash}</span>;
      },
    },
    {
      key: "date",
      text: "Dates",
      className: "status",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (record.created) ? <Fragment>{"Requested: " + Moment(new Date(record.created)).format("lll")}<br />{((record.date_timestamp)) ? "Submitted: " + Moment(record.date_timestamp * 1000).format("lll") : ""}</Fragment> : "";
      },
    },
    // {
    //   key: "rejection_reasion",
    //   text: "Reject Reason",
    //   className: "status",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return <span>{record.rejection_reasion}</span>;
    //   },
    // },
    // {
    //   key: "created",
    //   text: "Requested Date",
    //   className: "created",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return <Fragment>{Moment(new Date(record.created)).format("lll")}</Fragment>;
    //   },
    // },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <p className="mb-0">

              <>
                {(record.WithdrawlRequest == "1") ?
                  (inprogress) ? (selectedTransaction && selectedTransaction.id == record.id) ? <span>In progress</span>
                    : <div>

                    </div>
                    :
                    <div>
                      <button
                        disabled={inprogress}
                        // data-toggle="modal"
                        // data-target="#update-user-modal-approve"
                        className="btn btn-success btn-sm"
                        onClick={() => tranferNow(record)}
                        style={{ marginRight: "5px" }}
                      >
                        Transfer
                      </button>
                      <button
                        disabled={inprogress}
                        // data-toggle="modal"
                        // data-target="#update-user-modal-approve"
                        className="btn btn-success btn-sm"
                        onClick={() => rejectConfirm(record)}
                        style={{ marginRight: "5px" }}
                      >
                        Reject
                      </button>
                    </div>

                  : ((record.WithdrawlRequest == "2")) ? <span>Rejected <i class="fa fa-info-circle" onClick={() => showRejectModal(record)} style={{ cursor: "pointer" }} /></span> : ((record.WithdrawlRequest == "0")) ? <span>Transferred</span> : ""
                }
              </>
            </p>
          </Fragment>
        )
      }
    }
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const metadata = {
    name: "dbi",
    description: "dbiexc",
    url: "https://dbiexchange.com/",
    icons: ["https://dbiexchange.com/assets/img/logo.png"],
  };



  useEffect(() => {

    // const tronWeb = window.tronWeb;
    console.log("TRX connect  = ", walletConnectedtron, tronWeb)
    // if (tronWeb?.ready != undefined) {
    //   setWalletConnectedtron(tronWeb.ready)

    // }
    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: mainnet,
      projectId,
      enableAnalytics: true,
    });
    getData()
    const checkTronLink = () => {
      // if (typeof window.tronWeb !== 'undefined') {
      //   console.log('TronLink is installed');
      // } else {
      //   console.log('TronLink not installed');
      // }
    };

    console.log("admin_xrp_wallet=> ", localStorage.getItem("admin_xrp_wallet"))
    const checkWalletInstallation = async () => {
      try {
        let wdata = await isInstalled();
        console.log("wdata  ", wdata.result.isInstalled);
        if (wdata.result.isInstalled) {
          if (localStorage.getItem("admin_xrp_wallet")) {
            let waddress = localStorage.getItem("admin_xrp_wallet");
            
            setXrpwallet(waddress)
          }
        } else {
          localStorage.removeItem("admin_xrp_wallet")
        }
      } catch (error) {
        //setWalletStatus('Error checking Gem Wallet installation.');
        console.error(error);
      }
    };

    checkWalletInstallation();

    //checkTronLink();

    // Optionally listen for TronLink injection after page load
    // window.addEventListener('tronLinkInjected', checkTronLink);

    // Clean up event listener on unmount
    return () => {
      //window.removeEventListener('tronLinkInjected', checkTronLink);
    };
  }, [])

  useEffect(() => {
    console.log("tron effect", window?.tronWeb?.ready);
    if (window?.tronWeb?.ready) {
      setTronWeb(window?.tronWeb)
      setWalletConnectedtron(true)
      setAddresstron(window?.tronWeb?.defaultAddress?.base58)
    }
  }, [window])

  //useEffect(()=>{

  //  console.log(window?.tronWeb?.defaultAddress,'sadsaasd');

  // },[window?.tronWeb?.defaultAddress])

  const getData = () => {
    axios
      .get(baseUrl + "/api/userCoinwithdrawalHistory", {})
      .then((res) => {
        setState({ ...state, records: res.data, FilterRecords: res.data });
        setInprogress(false)
      })
      .catch();

    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        if (res.status === 200) {
          setCoinList(res.data);
        }
      })
      .catch();
  }

  const connectTrxWallet = async () => {
    console.log("connectTrxWallet============");

    try {
      console.log(window.tronWeb);
      if (typeof window.tronWeb === 'undefined') {

        toast.error("TronLink wallet is not installed. Please install TronLink.");

        return;
      }

      //  console.log("TRX connect ", window.tronWeb.ready, " = ", walletConnectedtron, window.tronWeb.defaultAddress.base58)
      let tAddress = window.tronWeb.defaultAddress.base58;
      // let maxRetries = 100;
      // while (!window.tronWeb.ready && maxRetries > 0) {
      //   await new Promise(resolve => setTimeout(resolve, 500)); // Wait 500ms
      //   maxRetries--;
      // }

      if (!window.tronWeb.ready) {
        toast.error("TronLink is installed but not logged in. Please log in to TronLink.");

        return;
      }

      // TronLink is ready
      //const tronWeb = window.tronWeb;


      if (tAddress) {
        setAddresstron(tAddress);
        setWalletConnectedtron(true);
        setTronWeb(window?.tronWeb)

        const balanceInSun = await tronWeb.trx.getBalance(tAddress);
        const balanceInTrx = tronWeb.fromSun(balanceInSun); // Convert from Sun (smallest unit) to TRX
        setBalancetron(balanceInTrx);
        console.log(balanceInTrx, "balanceTron");
        if (balanceInTrx == 0) {
          toast("Tron insufficient balance.", {
            position: toast.POSITION.TOP_CENTER
          });
        }

      } else {
        toast('TronLink is not connected to any account. Please connect your account in TronLink.', {
          position: toast.POSITION.TOP_CENTER
        });
        // alert('TronLink is not connected to any account. Please connect your account in TronLink.');

      }
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectTrxWallet = () => {
    setAddresstron(null);
    setWalletConnectedtron(false);
    setTronWeb(null)
  }

  const trxTransfer = async (data) => {
    try {
      console.log('walletConnectedtron ', walletConnectedtron);
      if (walletConnectedtron) {

        // const tronWeb = window.tronWeb;
        const address = addresstron
        //  tronWeb.defaultAddress.base58;
        console.log('tron Transfer initiate ', data.wallet_address, " = ", address);


        let finalAmount = data.coin_amount - (data.withdrawal_fees ?? 0);

        console.log('finalAmount : ', finalAmount);

        const amountInSun = finalAmount * 10 ** 6;


        const recipientAddress = data.wallet_address;
        console.log('amountInSun : ', amountInSun, " = ", recipientAddress, tronWeb);

        const transaction = await tronWeb.trx.sendTransaction(recipientAddress, amountInSun);
        //await transaction.wait();
        console.log('Transfer successful! Transaction hash: ' + transaction.txid);
        data.hash = transaction.txid;
        updateWithdrawal(data);
      } else {
        toast.error("Please connect TronLink");
      }
    } catch (e) {
      let messsage = "";
      if (e == "Confirmation declined by user") {
        messsage = "Transaction Declined by User"
      } else {
        messsage = JSON.stringify(e)
      }
      toast.error(messsage);
      console.log('trxTransfer : ', e);
    }
  }

  const handleGemWalletConnect = async () => {
    try {
      console.log("clicked");
      const installedResponse = await isInstalled();
      if (installedResponse.result.isInstalled) {
        console.log("clicked 2");
        const addressResponse = await getAddress();
        console.log("clicked ", addressResponse);
        if (addressResponse) {
          localStorage.setItem("admin_xrp_wallet", addressResponse.result?.address)
          setXrpwallet(addressResponse.result?.address);
        }
      } else {
        setError("XRP wallet is not installed.");
      }
    } catch (err) {
      console.error("Connection error: ", err);
      setError("Failed to connect to wallet. Please try again.");
    }
  };

  const handleGemWalletDisconnect = async () => {
    try {
      localStorage.removeItem("admin_xrp_wallet")
      setXrpwallet(null);

    } catch (err) {
      console.error("Connection error: ", err);

    }
  };

  const xrpTransfer = async (data) => {
    console.log('xrpTransfer : ', data);
    let requestAmount = parseFloat(data.coin_amount);
    let feesAmount = parseFloat(data.withdrawal_fees);
    // console.log('am : ', requestAmount, " fm ", feesAmount);
    requestAmount = requestAmount - feesAmount;
    // console.log('am : ', requestAmount, " fm ", requestAmount * 1000000);
    // toast.error("This coin coming soon", {
    //   position: toast.POSITION.TOP_CENTER
    // });

    const payment = {
      amount: requestAmount * 1000000, // In drops (1 XRP)
      destination: data.wallet_address,
    };
    console.log(payment);

    setLoading(true);
    try {
      const response = await sendPayment(payment);
      console.log("Transaction Hash: ", response.result?.hash);
      data.hash = response.result?.hash;
      updateWithdrawal(data);
    } catch (err) {
      console.error("Payment failed: ", err);
      setError("Payment failed. Please try again.");
    }
  }

  const updateWithdrawal = (data) => {
    axios
      .post(baseUrl + "/api/update_coin_withdrawal", data)
      .then((res) => {
        console.log(res);
        if (res) {
          toast("Successfully Transfered", {
            position: toast.POSITION.TOP_CENTER
          });
        }
        getData()
      })
      .catch();
  }

  const rejectTransaction = () => {
    let data = selectedTransaction;
    //data.rejectReason = "Not valid amount";

    console.log(selectedTransaction.rejectReason);

    if (!selectedTransaction.rejectReason || selectedTransaction.rejectReason == "") {
      toast.error("Please Enter Reject Reason");
      return
    }

    axios.post(baseUrl + "/api/reject_coin_withdrawal", data)
      .then((res) => {
        console.log(res);
        if (res) {
          closeKycModal()
          toast("Successfully Rejected", {
            position: toast.POSITION.TOP_CENTER
          });
        }
        getData()
      })
      .catch();
  }


  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  }

  const switchChain = async (
    chain,
    chainName,
    rpcUrl,
    explorer,
    native,
    symbol
  ) => {
    try {
      console.log(window.ethereum, chain, chainName, rpcUrl, explorer, native, symbol);
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      })

      return { status: true }

    } catch (switchError) {
      console.log(switchError);
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain,
                chainName: chainName,
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorer],
                nativeCurrency: {
                  name: native,
                  symbol: symbol,
                  decimals: 18,
                },
              },
            ],
          });
          return { status: true }
        } catch (addError) {
          console.error(addError);
          return { status: false }
        }
      } else {
        console.error(switchError);
        return { status: false }

      }
    }
  };

  const switchToChain = async (record) => {
    try {
      console.log(record);

      let data = coinList.find((val) => val.id == record.cryptocoin_id)
      console.log(data, " type ", chainType, " = ", data[chainType + "chain_id"], " = ", chainId);
      let returnValue = true;

      if (chainId != data[chainType + "chain_id"]) {
        // returnValue = false;


        toast.error(`Metamask wallet network need to change.`);
        //  setDisable(false);
        // return false;


        let chainSwitch = await switchChain(
          data[chainType + "network"],
          data[chainType + "chain_name"],
          data[chainType + "rpc_url"],
          data.explorer_url,
          data.native_currency,
          data.symbol
        );
        console.log(chainSwitch);
        if (chainSwitch.status) {
          toast("Metamask wallet network is changed.", {
            position: toast.POSITION.TOP_CENTER
          });
        }
        return returnValue;
      } else {
        return true
      }

    } catch (e) {
      console.log(e);
      return false
    }

  };

  // const network = "https://solana-mainnet.g.alchemy.com/v2/KL0XtkBmpG0pMfcqD7B8UfFMezVg7Wfm";
  //const network = "https://solana-mainnet.g.alchemy.com/v2/wy3C3ZExqNtP_Ff9QG9GYpvGVA6D-vnu";
  // const network = "https://solana-mainnet.g.alchemy.com/v2/C6ZbnVQpVMNg9okYnmITRSvJpuT2Jn4r";
  const network = "https://api.mainnet-beta.solana.com";
  //  const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");

  //const connection = new Connection(network, 'confirmed');

  async function getSolBalance() {
    console.log(wallet.publicKey)
    if (!wallet.connected || !wallet.publicKey) {
      //  setownerBlanace(0); // Handle if wallet is not connected
      return;
    }
    //  let url = (chainType == "test_") ? "devnet" : "mainnet-beta";
    // console.log(url)

    const provider = window.solana;
    const response = await provider.connect();
    const publicKey = response.publicKey;
    // console.log("Connected Wallet Public Key:", publicKey.toString());
    //const connection = new Connection(clusterApiUrl(url), "confirmed");

    // const connection = new Connection(clusterApiUrl(url), "confirmed");



    try {
      //const userPublicKey = new PublicKey(publicKey.toString());
      // console.log(userPublicKey)

      // const network = clusterApiUrl("mainnet-beta");
      // const connection = new Connection(network,"confirmed");
      // Get the balance in lamports (1 SOL = 10^9 lamports)



      const balanceLamports = await axios({
        method: "POST",
        data: {
          key: publicKey.toString()
        },
        url: baseUrl + "/api/get_sol_balance",
      })
      //await connection.getBalance(userPublicKey);
      console.log(balanceLamports)

      // Convert lamports to SOL
      const balanceSol = (balanceLamports.data) ? balanceLamports.data.balance / LAMPORTS_PER_SOL : 0;

      console.log(`SOL Balance: ${balanceSol}`);
      return balanceSol
      //setownerBlanace(balanceSol); // Update the state with the SOL balance
    } catch (error) {
      console.error("Error fetching SOL balance:", error);
      // setownerBlanace(0); // Handle the error by resetting balance or other means
    }
  }

  const solTransfer = async (connection, signedTransaction, data) => {
    try {
      const signature = await connection.sendRawTransaction(
        signedTransaction.serialize()
      );
      console.log("2 helloere");

      // Confirm the transaction
      // const confirmation = await connection.tranferNow(
      const confirmation = await connection.confirmTransaction(
        signature,
        "confirmed"
      );

      console.log("3 helloere");

      if (confirmation) {
        data.hash = signature;
        updateWithdrawal(data);
      }

      // await transferTokens(wallet.publicKey, recivetoken);

      console.log("Transaction confirmation:", confirmation, signature);
    } catch (e) {
      console.log(e);
    }
  }

  const transferSOL = async (data) => {
    console.log("transferSOL");
    if (!wallet.connected || !wallet.publicKey) {
      alert("Wallet not connected!");
      return;
    }
    let valbal = await getSolBalance()

    toast.success(`Wallet Balance is ${valbal}`);

    if (valbal > data.coin_amount) {


      console.log(wallet.publicKey, "senderhereiam");
      //  console.log(new PublicKey(data.wallet_address), "recihereiam");

      try {
        const provider = window.solana;
        const response = await provider.connect();
        const publicKey = response.publicKey;
        console.log("Connected Wallet Public Key:", publicKey.toString());

        let finalAmount = data.coin_amount - (data.withdrawal_fees ?? 0);
        //mainnet-beta
        //  let url = "https://solana-mainnet.g.alchemy.com/v2/C6ZbnVQpVMNg9okYnmITRSvJpuT2Jn4r";
        //  const connection = new Connection(clusterApiUrl(url), "confirmed");

        const network = "https://prettiest-aged-butterfly.solana-mainnet.quiknode.pro/cba09272f1c1c65754abe6abab326136b2e27239";
        const connection = new Connection(network, "confirmed");

        console.log("finalAmount ", finalAmount);

        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: wallet.publicKey,
            toPubkey: new PublicKey(data.wallet_address),
            lamports: parseFloat(finalAmount) * LAMPORTS_PER_SOL,
          })
        );

        //  const userPublicKey = new PublicKey(publicKey.toString());


        transaction.feePayer = wallet.publicKey;
        console.log("hello", publicKey.toString());

        let { blockhash } = await connection.getLatestBlockhash();
        console.log("blockhash ", blockhash);
        transaction.recentBlockhash = blockhash;


        const signedTransaction = await wallet.signTransaction(transaction);
        console.log(" 1 helloere");
        // Send the transaction3
        toast.success("Please wait, Your Transaction is under process.");
        setTimeout(() => {
          console.log("This message is displayed after 5 seconds.");
          solTransfer(connection, signedTransaction, data)

        }, 5000);

      } catch (error) {
        console.error("Transfer error:", error);
        //alert(`Transfer error: ${error.message}`);
        if (error.message.includes("Non-base58 character")) {
          alert("Recipeint Address in Incorrect !");
        } else {
          toast(error.message, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
    } else {
      toast("Insufficient Balance", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const tranferNow = async (data) => {
    console.log(isConnected, data);
    let index = coinAvailable.findIndex((val) => val == data.cryptocoin_id)
    if (index != -1) {
      setSelectedTransaction(data)
      if (data.cryptocoin_id == 6) {
        transferSOL(data)
      } else if (data.cryptocoin_id == 3) {
        trxTransfer(data)
      } else if (data.cryptocoin_id == 14) {
        xrpTransfer(data)
      } else {
        data.coin_data = coinList.find((val) => val.id == data.cryptocoin_id);
        if (isConnected) {
          const flag = await switchToChain(data);
          console.log(flag);
          if (flag) {
            console.log("transfer");
            //if (true) {

            if (data.type == "token") {
              transferToken(data)
            } else if (data.type == "coin") {
              transferCoin(data)
            }
            //}
          }
        } else {
          toast("Please connect meta mask", {
            position: toast.POSITION.TOP_CENTER
          });
        }

      }
    } else {
      toast("This coin is not available for withdrawl.", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }


  const transferToken = async (data) => {
    try {

      console.log(data);
      const provider = await getSignerOrProvider();
      //  const Balancec = await provider.(address);
      //  console.log("=> ",JSON.stringify(data.coin_data[chainType + "abi"]));
      var nAbi
      if (chainType == "test_") {
        nAbi = (data.cryptocoin_id != 50) ? test_abi : eth_test_abi;
      } else {
        nAbi = abi;
        if (data.cryptocoin_id == 50) {
          nAbi = eth_abi;
        } else if (data.cryptocoin_id == 12) {
          nAbi = shib_abi;
        } else if (data.cryptocoin_id == 9) {
          nAbi = dodge_abi
        } else if (data.cryptocoin_id == 18) {
          nAbi = link_abi
        } else if (data.cryptocoin_id == 11) {
          nAbi = mana_abi
        } else if (data.cryptocoin_id == 17) {
          nAbi = dot_abi
        } else if (data.cryptocoin_id == 16) {
          nAbi = etc_abi
        } else if (data.cryptocoin_id == 46) {
          nAbi = dbi_abi
        }
      }

      console.log(address, " =nbi= ", nAbi);

      let contract = new Contract(
        data.coin_data[chainType + "contract_address"],
        // "0xd200828D90B2a88B6C3fb8554a308c28e68C4B13",
        nAbi,
        //  (chainType == "test_") ? test_abi:abi,
        // data.coin_data[chainType + "abi"],
        provider
      );

      const Balancec = await contract.balanceOf(address);
      console.log(Balancec);

      let bal = Number(Balancec) / 10 ** data.coin_data.decimal;
      //  console.log(bal, data.coin_amount);

      let finalAmount = data.coin_amount - (data.withdrawal_fees ?? 0);
      console.log(bal, "finalAmount => ", finalAmount);

      if (bal >= finalAmount) {
        setInprogress(true)
        let amount = parseUnits(finalAmount.toString(), Number(data.coin_data.decimal));
        console.log("amount = ", amount);
        const balance = await contract.transfer(
          data.wallet_address,
          amount
        );
        console.log("balance => ", balance);
        const receipt = await balance.wait();

        console.log("receipt => ", receipt);

        if (receipt) {
          data.hash = balance.hash;
          updateWithdrawal(data);
        }

        if (balance.hash) {
          setTimeout(() => {
            // data.hash = balance.hash;
            // updateWithdrawal(data);
          }, 5000)
        }
      } else {
        console.log("Insufficient => ");

        toast("Insufficient Balance.", {
          position: toast.POSITION.TOP_CENTER
        });
      }


    } catch (e) {
      console.log(e);
      setInprogress(false)
      toast(e.info.error.message, {
        position: toast.POSITION.TOP_CENTER
      });
      // console.log(e.info);
    }
  }

  const transferCoin = async (data) => {
    try {
      if (address != data.wallet_address) {

        const provider = new BrowserProvider(walletProvider);

        const Balance = await provider.getBalance(address);
        let bal = Number(Balance) / 10 ** 18;

        let finalAmount = data.coin_amount - (data.withdrawal_fees ?? 0);

        console.log(bal, finalAmount);
        //  return
        if (bal > finalAmount) {
          setInprogress(true)
          const wallet = await provider.getSigner();
          const Amount = parseEther(finalAmount.toString())
          // parseFloat(data.coin_amount) / parseFloat(10 ** 18);

          const gasEstimate = await provider.estimateGas({
            to: data.wallet_address,
            value: Amount,
          });

          const gasPrice = (await provider.getFeeData()).gasPrice;

          //const gasCost = gasEstimate * gasPrice;
          //const amountToSend = Balance -gasCost;

          const data1 = {
            to: data.wallet_address,
            value: Amount,
            gasLimit: gasEstimate,
            gasPrice: gasPrice
          };

          const tx = await wallet.sendTransaction(data1);
          console.log(tx, 'trnsatin ');
          const receipt = await tx.wait();


          console.log(receipt, Amount);
          if (receipt.hash) {
            data.hash = receipt.hash;
            updateWithdrawal(data);
          }
        } else {
          toast("Insufficient Balance.", {
            position: toast.POSITION.TOP_CENTER
          });
        }
      } else {
        toast("Invalid transfer address", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (e) {
      console.log(e.info.error.message);
      setSelectedTransaction({})
      setInprogress(false)
      toast(e.info.error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const sendXrp = async () => {
    const payment = {
      amount: "1000000", // In drops (1 XRP)
      destination: "rLWQskMM8EoPxaLsmuQxE5rYeP4uX7dhym",
    };
    setLoading(true);
    try {
      const response = await sendPayment(payment);
      console.log("Transaction Hash: ", response.result?.hash);
    } catch (err) {
      console.error("Payment failed: ", err);
      setError("Payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };


  // state = {
  //   records: [],
  // };

  // state = {
  //   currentRecord: {
  //     id: "",
  //     coin_amount: "",
  //     user_id: "",
  //     approve: "",
  //     reject: "",
  //     status: "",
  //   },
  // };

  //getData = getData.;

  //const copyCodeToClipboard = () => {
  //const el = Copy;
  // el.select();
  // document.execCommand("copy");
  //setState({ copySuccess: true });
  //};
  // componentDidMount() {
  //   getData();
  // }

  //  const componentWillReceiveProps(nextProps) {
  //     getData();
  //   }


  const editRecord = (record) => {
    setCurrentRecord(record);
  }

  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  }
  const supportClick = (e) => {
    var html = [];

    state.records.map((value) => {
      console.log("valuevaluevaluevalue", value);
      if (e.target.value == "Pending") {
        if (value.WithdrawlRequest == "4") {
          html.push(value);
        }
      }
      if (e.target.value == "Approved") {
        if (value.WithdrawlRequest == "1") {
          html.push(value);
        }
      }
      if (e.target.value == "Reject") {
        if (
          value.WithdrawlRequest != "0" &&
          value.WithdrawlRequest != "1" &&
          value.WithdrawlRequest != "4"
        ) {
          html.push(value);
        }
      }
      if (e.target.value == "select") {
        html.push(value);
      }
    });
    setState({ FilterRecords: html });
  };

  const showFilterList = () => {
    console.log("showqfilterlist");

    // const html = [];

    // html.push(
    //   <select
    //     className="form-control"
    //     id="exchange"
    //     name="supportFilter"
    //     onClick={supportClick}
    //   >
    //     <option value="select" id="">
    //       select
    //     </option>
    //     <option value="Pending">Pending</option>
    //     <option value="Reject">Reject</option>
    //     <option value="Approved">Approved</option>
    //   </select>
    // );

    //return <div className="support-select">{html}</div>;
  };

  const onSelectChange = (e) => {
    console.log(e.target.value);
    var html = [];
    console.log(state.records);
    console.log("e ", e.target.value);
    // if (e.target.value) {
    setDataFilter({ ...dataFilter, [e.target.name]: e.target.value })
    if (e.target.name == "coinFilter" && e.target.value != "") {
      state.records.map((value) => {
        // console.log("valuevaluevaluevalue", value);
        if (e.target.value == value.cryptocoin_id) {
          html.push(value);
        }
      });
    } else if (e.target.name == "typeFilter" && e.target.value != "") {
      console.log("typeFilter ");
      state.records.map((value) => {
        //  console.log("valuevaluevaluevalue", value);
        if (e.target.value == value.WithdrawlRequest) {
          html.push(value);
        }
      });
    } else {
      console.log("else ");
      html = state.records;
    }
    // }
    console.log(html.length);
    setState({ ...state, FilterRecords: html });
  }

  const closeKycModal = () => {
    setModalOpen(false)
  }

  const closeRejectModal = () => {
    setRejectModalOpen(false)
    setSelectedTransaction({})
  }



  const onChangeHandleReject = (e) => {
    setSelectedTransaction({ ...selectedTransaction, rejectReason: e.target.value })
  }

  const rejectConfirm = (data) => {
    setSelectedTransaction(data)
    setModalOpen(true)
  }

  const showRejectModal = (data) => {
    setSelectedTransaction(data)
    setRejectModalOpen(true)

  }

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />

        <CoinWithdrawalReject record={currentRecord} />
        <CoinWithdrawalApprove record={currentRecord} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>
            <div >
              <h4 className="mt-2 man_hadding mb-3">
                Withdrawal Coin Request List
              </h4>
            </div>
            <div className="row">
              <div className="">
                <w3m-button balance="hide" />
              </div>

              <div style={{ marginRight: "5px", marginLeft: "5px" }}>
                <WalletModalProvider>
                  <WalletMultiButton />
                </WalletModalProvider>
              </div>
              <div>   {(walletConnectedtron) ? <> <button className="btn btn-success" onClick={() => disconnectTrxWallet()}>TronLink Connected {addresstron.substring(0, 20)}</button>  </> :
                <button className="btn btn-danger" onClick={() => connectTrxWallet()}>Connect TronLink Wallet</button>
              }
              </div>
              <div>
                {!xrpwallet ? (
                  <button onClick={() => handleGemWalletConnect()} className="btn" style={{ backgroundColor: "#00ffff" }}>Connect Gem Wallet</button>
                ) : (
                  <>
                    <button onClick={handleGemWalletDisconnect} disabled={loading} className="btn" style={{ backgroundColor: "#00ffff" }}>
                      <span>GemWallet Connected: {xrpwallet}</span>
                    </button>
                  </>
                )}
                {error}
              </div>
            </div>
            <div className="white_box">
              <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-md-3">

                  <select className="form-control" id="exchange" name="coinFilter" onChange={(e) => onSelectChange(e)}>
                    <option value="">All Coins</option>
                    {coinList?.map((e) =>
                      <option value={e.id}>{e.name}</option>
                    )}
                  </select>
                </div>
                <div className="col-md-3">

                  <select className="form-control" id="exchange" name="typeFilter" onChange={(e) => onSelectChange(e)}>
                    <option value="">All Request</option>
                    <option value="0">Transferred</option>
                    <option value="1">Pending</option>
                    <option value="2">Rejected</option>

                  </select>
                </div>
              </div>

              <div className="select_change_r">
                <div className="scrollmenu coin_w">
                  <ReactDatatable
                    config={config}
                    records={
                      state.FilterRecords

                    }
                    columns={columns}
                    onPageChange={pageChange}
                    className="scrolling-table table table-bordered table-striped"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}
        <Modal
          show={modalOpen}
          onHide={closeKycModal}
          backdrop="static"
          keyboard={false}
          className="rejectbody"
        >
          <Modal.Body>
            <div className="kyc_popop tc">
              <div>
                <h4 className="man_hadding">Reject Reason</h4>
              </div>
              <div className="row">
                <div className="col-md-12 mb-2">
                  <input type="text" className='form-control' value={selectedTransaction.rejectReason} onChange={(e) => onChangeHandleReject(e)} placeholder="Reason" />
                </div>
                <div className="col-md-6">
                  <button className="w100px btn btn-primary" onClick={rejectTransaction}>
                    Okay!
                  </button>
                </div>
                <div>
                  <div className="col-md-6">
                    <button className="w100px btn btn-primary" onClick={closeKycModal}>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={rejectModalOpen}
          onHide={closeRejectModal}
          backdrop="static"
          keyboard={false}
          className="rejectbody"
        >
          <Modal.Body>
            <div className="kyc_popop tc">
              <div>
                <h4 className="man_hadding">Rejected Reason</h4>
              </div>
              <div className="">
                <div className="col-md-12">
                  <p type="text" className='' style={{ wordBreak: "break-word" }}>{selectedTransaction.rejection_reasion}</p>
                </div>


                <div className="">
                  <button className="btn btn-primary" onClick={closeRejectModal}>
                    Close
                  </button>

                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>



      </div>
    </div>
  );

}

// CoinWithdrawal.propTypes = {
//   auth: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   records: state.records,
// });

export default CoinWithdrawal
