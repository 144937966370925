import React, { useState, useEffect } from 'react';
import classnames from "classnames";
import PropTypes from "prop-types";
import Moment from 'moment';
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonTax from "./RejectButtonTax";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { Modal, Button } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../../../actions/baseUrl";

const VerifyDocument4 = ({ auth, errors: propErrors, verifyStatus, record, userrecords, match, disable: propDisable }) => {
    console.log("v4 ", record, match);
    const [state, setState] = useState({
        records: [],
        id: "",
        selfie_image: '',
        selfie_status: "",
        reject: '',
        errors: {},
        type: 24,
        disable: false,
        isKycModalOpen: false,
        d1: '',
        d2: '',
        d3: '',
        d4: ''
    });

    useEffect(() => {
        if (record) {
            setState((prevState) => ({
                ...prevState,
                id: record.id,
                selfie_status: record.selfie_status,
                selfie_image: record.selfie_image,
                reject: record.d4_rejectResion,
                records: record,
                disable: propDisable
            }));
        }
    }, [record, propDisable]);

    useEffect(() => {
        if (propErrors) {
            setState((prevState) => ({
                ...prevState,
                errors: propErrors
            }));
        }
    }, [propErrors]);

    useEffect(() => {
        if (userrecords) {
            setState((prevState) => ({
                ...prevState,
                tstatus: userrecords.status
            }));
        }
    }, [userrecords]);

    const getNodesToRemoveFromElement = (stringContent) => {
        const div = document.createElement("div");
        div.innerHTML = stringContent;
        return div.textContent || div.innerText || "";
    };

    const onChange = (e) => {
        if (e.target.id === 'reject') {
            setState((prevState) => ({
                ...prevState,
                reject: e.target.value
            }));
        }
    };

    const handleCommentChange = (event, editor) => {
        const data = editor.getData();
        setState((prevState) => ({
            ...prevState,
            description: data,
            showResults: prevState.name === null ? false : true
        }));
    };

    const getData = () => {
        axios.post(`${baseUrl}/api/rejectReason-By-Id-Data`, { _id: state.id })
            .then(res => {
                setState((prevState) => ({
                    ...prevState,
                    d1: res.data.d1_rejectResion,
                    d2: res.data.d2_rejectResion,
                    d3: res.data.d3_rejectResion,
                    d4: res.data.d4_rejectResion,
                    isKycModalOpen: true
                }));
            })
            .catch(err => console.error(err));
    };

    const closeModel = () => {
        setState((prevState) => ({
            ...prevState,
            isKycModalOpen: false
        }));
    };

    const onUpdate = (e) => {
        e.preventDefault();
        const newUser = {
            _id: state.id,
            type: "d4"
        };
        setState((prevState) => ({
            ...prevState,
            disable: true
        }));
        verifyStatus(newUser);
    };

    const {
        errors,
        selfie_status,
        selfie_image,
        records,
        disable,
        isKycModalOpen,
        d1,
        d2,
        d3,
        d4
    } = state;

    return (
        <>
            {selfie_status !== undefined && (
                <>

                    <p class="mb-3" style={{ fontSize: "20px" }}>Selfie</p>
                    <form noValidate onSubmit={onUpdate} id="">
                        {(record?.utility_date) ? <p><b>Date:</b> {Moment(record?.utility_date).format("lll")}</p> : ""}
                        <div style={{  }}>
                            <p style={{height:"51px"}}>
                                Status: {selfie_status == 3 ? (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            getData();
                                        }}
                                        type="button"
                                        className="btn btn_man"
                                    >
                                        Rejected <i className="fa fa-info-circle"></i>
                                    </button>
                                ) : (selfie_status == 2 ? "Verified" : "Pending")
                                }

                            </p>
                        </div>
                        <div>
                        {selfie_image && (
                            <a href={`${baseUrl}/static/upload/selfie/${selfie_image}`} target="_blank" rel="noopener noreferrer">
                                <img src={`${baseUrl}/static/upload/selfie/${selfie_image}`} className="img-thumbnail" alt="Selfie" style={{width:"auto"}}/>
                            </a>
                        )}
                        {/* <p> <b> </b></p> */}

</div>

                        {match.params.type == 2 && selfie_status != '2' && selfie_status != '3' && (
                            <div style={{ display: selfie_status ? 'block' : 'none' }}>
                                <button type="submit" className='btn btn-primary' disabled={disable}>Verify</button>
                            </div>
                        )}
                    </form>

                    {match.params.type == 2 && selfie_status != '2' && selfie_status != '3' && (

                        <RejectButtonTax record={records} docStatus={selfie_status} rejectStatus={state.reject} />
                    )}

                    <Modal
                        show={isKycModalOpen}
                        onHide={closeModel}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Body>
                            <div className="kyc_popop tc">
                                <h2>
                                    <h3 className="man_hadding">Reject Reason</h3>
                                    {state.type === 21 && <p className="rejectReason" style={{ wordBreak: "break-word" }}>{d1}</p>}
                                    {state.type === 22 && <p className="rejectReason" style={{ wordBreak: "break-word" }}>{d2}</p>}
                                    {state.type === 23 && <p className="rejectReason" style={{ wordBreak: "break-word" }}>{d3}</p>}
                                    {state.type === 24 && <p className="rejectReason" style={{ wordBreak: "break-word" }}>{d4}</p>}
                                </h2>
                                <div>
                                    <button className="btn btn-primary" onClick={closeModel}>
                                        Okay!
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    );
};

VerifyDocument4.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyDocument4));
