import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Modal } from "react-bootstrap";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import * as myConstList from "../../actions/baseUrl";
import VerifyKyc from "./VerifyKyc";

const baseUrl = myConstList.baseUrl;

const UsersPendingRecord = () => {
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [nationality, setNationality] = useState("indian");
  const [popUpModal, setPopModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [currentRecord, setCurrentRecord] = useState({
    id: "",
    name: "",
    email: "",
    password: "",
    password2: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await axios.post(baseUrl + "/api/user-pending-data", { lang: 1 });
      setRecords(res.data);
      filterDataByNationality(res.data, nationality);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterDataByNationality = (data, selectedNationality) => {
    // const filteredData = data.filter((item) =>
    //   selectedNationality === "indian" ? item.country_code === "+91" : item.country_code !== "+91"
    // );
    setFilteredRecords(data);
  };

  const handleChange = (e) => {
    const selectedNationality = e.target.value;
    setNationality(selectedNationality);
    filterDataByNationality(records, selectedNationality);
  };

  const deleteRecord = async (record) => {
    try {
      const res = await axios.post(baseUrl + "/api/user-delete", { _id: record._id });
      if (res.status === 200) {
        toast(res.data.message, { position: toast.POSITION.TOP_CENTER });
        getData();
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const pageChange = (pageData) => {
    console.log("OnPageChange===> ", pageData);
  };

  const openPopUpModel = (data) => {
    console.log(data)
    setSelectedData(data)
    // sessionStorage.setItem("popshow", false);
    setPopModalOpen(true);
  };

  const columns = [
    {
      key: "_id",
      text: "#",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "id",
      text: "Id",
      align: "left",
      sortable: true,
    },
    {
      key: "firstName",
      text: "Name",
      align: "left",
      sortable: true,
      cell: (record) => <>{record?.firstName ? `${record.firstName} ${record.lastName}` : "-"}</>,
    },
    {
      key: "mobile_no",
      text: "Mobile Number",
      align: "left",
      sortable: true,
      cell: (record) => <>{record.mobile_no || "-"}</>,
    },
    {
      key: "email",
      text: "Email",
      align: "left",
      sortable: true,
    },
    {
      key: "doc_type",
      text: "Doc Type",
      align: "left",
      sortable: true,
      cell: (record) => <Fragment>{record.doc_type}</Fragment>,
    },
    {
      key: "identity_status",
      text: "Identity Doc",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {record.passport_status == "1" || record.passport_status == "0"
            ? "Pending"
            : record.passport_status == "2"
              ? "Verified"
              : (
                <a href={`/reject-reason/${record.id}/21`} className="btn5">
                  Rejected <i className="fa fa-info-circle"></i>
                </a>
              )}
        </Fragment>
      ),
    },
    {
      key: "utility_status",
      text: "Utility Doc",
      align: "left",
      sortable: true,
      cell: (record) => (
       
        <Fragment>
         
          {record.utility_status == "2"
            ? "Verified"
            : record.utility_status == "3"
              ? (
                <a href={`/reject-reason/${record.id}/25`} className="btn5">
                  Rejected <i className="fa fa-info-circle"></i>
                </a>
              )
              : record.utility_status == "0" || record.utility_status == "1"
                ? "Pending"
                : "-"}
        </Fragment>
      ),
    },
    {
      key: "selfie_status",
      text: "Selfie",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {record.selfie_status === "2"
            ? "Verified"
            : record.selfie_status === "3"
              ? (
                <a href={`/reject-reason/${record.id}/24`} className="btn5">
                  Rejected <i className="fa fa-info-circle"></i>
                </a>
              )
              : record.selfie_status === "1"
                ? "Pending"
                : "-"}
        </Fragment>
      ),
    },
    {
      key: "createdAt",
      text: "Date",
      align: "left",
      sortable: true,
      cell: (record) => (
        <Fragment>
          {record.kyc_start_date
            ? Moment(record.kyc_start_date).format("MMM DD YYYY hh:mm A")
            : Moment(record.createdAt).format("MMM DD YYYY hh:mm A")}
        </Fragment>
      ),
    },
    {
      key: "action",
      text: "Action",
      align: "left",
      sortable: false,
      cell: (record) => (
        <Fragment>
          <button onClick={() => openPopUpModel(record)} className="admin_btn">verify</button>
          {/* <a href={`/verifyKyc/${record.id}/2`} className="admin_btn" target="_blank">
            Verify
          </a> */}
        </Fragment>
      ),
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 50, 100],
    filename: "Verify Pending List",
    no_data_text: "No user found!",
    button: { print: false, csv: true },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const closePopUpModel = () => {
    // sessionStorage.setItem("popshow", false);
    getData();
    setPopModalOpen(false);

  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <UserAddModal />
        <UserUpdateModal record={currentRecord} />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>
            <h4 className="mt-2 man_hadding mb-3">Korean Users Pending KYC List</h4>
            <div className="white_box">
              <ReactDatatable config={config} records={filteredRecords} columns={columns} page={1} onPageChange={pageChange} />
            </div>
          </div>
        </div>
        <ToastContainer />
        <Modal
          style={{ width: "100%" }}
          show={popUpModal}
          size="lg"
          fullscreen={true}
          onHide={closePopUpModel}
          keyboard={false}
        >
          <Modal.Header >
            <div style={{height:"28px"}}>
              {/* <button className="admin_btn" onClick={() => closePopUpModel()} style={{position:"absolute",right:"25px"}} >X</button> */}
              <i style={{"font-size":"24px" , position:"absolute",right:"25px",cursor:"pointer"}} class="fa fa-close" onClick={()=>closePopUpModel()}></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            {(Object.keys(selectedData).length) ?

              <VerifyKyc match={selectedData} /> : ""}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

// UsersPendingRecord.propTypes = {
//   auth: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({ auth: state.auth });

export default UsersPendingRecord
//connect(mapStateToProps)(UsersPendingRecord);
