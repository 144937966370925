import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Modal } from "react-bootstrap";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer } from "react-toastify";

import Moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import * as myConstList from "../../actions/baseUrl";
import VerifyPersonalDetails from "../partials/verify/VerifyPersonalDetails";
import VerifyKyc from "./VerifyKyc";

const baseUrl = myConstList.baseUrl;

const ProfileView = () => {
  const { id } = useParams();
  console.log("getdata", id)
  const [state, setState] = useState({});
  const [currentRecord, setCurrentRecord] = useState({});


  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    // console.log("getdata", this.props)
    // const { user } = this.props.auth;
    axios
      .post(baseUrl + "/api/User-By-Id-Data", { _id: id })
      .then(res => {
        console.log("responseapi", res.data)
        if (res.data) {
          setState(res.data);
        } else {
          toast("Invalid User", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch()
  }


  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />      
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>
            <h4 className="mt-2 man_hadding mb-3">Profile</h4>
            <div className="white_box">

              <div className="in_page">

                <div className="user-personal">
                  <div className="row">
                    <div className="" style={{marginLeft:"20px",marginRight:"20px"}}>
                      <div className="user-detail-inner-main d-flex align-items-center">
                        {(state?.selfie_image) ?
                          <img src={`${baseUrl}/static/upload/selfie/${state?.selfie_image}`} className="img-thumbnail" alt="Selfie" style={{ height: "300px" }} /> : ""}
                      </div>
                    </div>

                    <div className="col-md-9">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="BankName">Name : </h5>
                        <p>{state?.firstName} {state?.lastName}</p>
                      </div>
                      <div className="row">



                        <div className="col-md-4">
                          <div className="user-detail-inner-main d-flex align-items-center">
                            <h5 htmlFor="AccNum">Email :</h5>
                            <p>{state?.email}</p>
                          </div>
                        </div>


                        <div className="col-md-4">
                          <div className="user-detail-inner-main d-flex align-items-center">
                            <h5 htmlFor="AccNum">Mobile :</h5>
                            <p>{(state?.country_code) ? "+" + state?.country_code + " " : ""}{state?.mobile_no}</p>
                          </div>
                        </div>

                        {(state.dob) ?
                          <div className="col-md-4">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="BranchName">DOB :</h5>
                              <p>{state.dob}</p>
                            </div>
                          </div> : ""}
                        {(state.Address) ?
                          <div className="col-md-8">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccNum">Address :</h5>
                              <p>{state.Address}</p>
                            </div>
                          </div>
                          : ""}
                        {(state.country) ?
                          <div className="col-md-4">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccHolder">Country :</h5>
                              <p>{state.country_name}</p>
                            </div>
                          </div>
                          : ""}
                        {(state.city) ?
                          <div className="col-md-3">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccHolder">City :</h5>
                              <p>{state.city}</p>
                            </div>
                          </div>
                          : ""}
                        {(state.state) ?
                          <div className="col-md-3">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccHolder">State :</h5>
                              <p>{state.state}</p>
                            </div>
                          </div>
                          : ""}
                        {(state.zipcode) ?
                          <div className="col-md-3">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccHolder">Zipcode :</h5>
                              <p>{state.zipcode}</p>
                            </div>
                          </div>
                          : ""}

                        {(state.kyc_start_date) ?
                          <div className="col-md-6">
                            <div className="user-detail-inner-main d-flex align-items-center">
                              <h5 htmlFor="AccHolder">Submit Date :</h5>
                              <p>{Moment(state.kyc_start_date).format("lll")}</p>
                            </div>
                          </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ToastContainer />

      </div>
    </div>
  );
};





export default ProfileView